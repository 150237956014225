/* Constants available to all components */


// GENERAL

@global-size-app-min-width: 1440px;
@global-size-app-min-height: 750px;


// COLORS (GENERAL)

@global-color-vitu-blue-1: #013D4F;
@global-color-vitu-blue-1-alpha-50: #013D4F80;
@global-color-vitu-blue-2: #4FC8EC;
@global-color-vitu-blue-2-alpha-50: #4FC8EC80;
@global-color-vitu-green: #28839E;

@global-color-panel-background: #F0FCFF;
@global-color-scrollbar: #D4D4D4;

@global-color-warning: #FF3940;
@global-color-no: #FA1E25;
@global-color-yes:#04D858;


// COLORS (STATUS)

@global-color-status-failed: #DB4437;
@global-color-status-pending: #F5C505;
@global-color-status-success:#21B57A;

@global-color-status-rejected:#FF5C00;
@global-color-status-funded:#367EEB;
@global-color-status-refunded:#9C18DA;

@global-color-status-partially-refunded: #9c18da80;
@global-color-status-voided: #FF8A00;

// @global-color-status-settled:#367EEB;

@global-color-status-user-locked: #70757A;
@global-color-status-user-unlocked: @global-color-yes;

@global-color-status-event-delivery-not-delivered: @global-color-no;
@global-color-status-event-delivery-delivered: @global-color-yes;
@global-color-status-event-delivery-not-attempted: #000;

@global-color-status-sftp-file-received: #21B57A;
@global-color-status-sftp-file-processing: #F5C505;
@global-color-status-sftp-file-processed: #8B4FEC;
@global-color-status-sftp-file-failed: #DB4437;
@global-color-status-sftp-file-omitted: #000;
@global-color-status-sftp-file-error: #FF5C00;

@global-color-status-funding-item-processing: #F5C505;
@global-color-status-funding-item-success: #21B57A;
@global-color-status-funding-item-error: #DB4437;
@global-color-status-funding-item-on-hold: #F5C505;
@global-color-status-funding-item-no-funding: #FF5C00;
@global-color-status-funding-item-funded: #367EEB;
@global-color-status-funding-item-insufficient-funds: #F44336;
@global-color-status-funding-item-missing-balance: #000;

@global-color-status-funding-settlement-item-processed: #8B4FEC;
@global-color-status-funding-settlement-item-pending: #F5C505;
@global-color-status-funding-settlement-item-rejected: #FF5C00;

@global-color-status-pos-terminal-suspended: #DB4437;
@global-color-status-pos-terminal-backup: #367EEB;
@global-color-status-pos-terminal-active: #00C875;
@global-color-status-pos-terminal-defective: #FF5C00;
@global-color-status-pos-terminal-validation: #F5C505;
@global-color-status-pos-terminal-reassigned: #9C18DA;
@global-color-status-pos-terminal-not-boarded: #000;

@global-color-status-digital-wallet-certificate-new: #00C875;
@global-color-status-digital-wallet-certificate-registered: #8B4FEC;
@global-color-status-digital-wallet-certificate-expired: #DB4437;
@global-color-status-digital-wallet-certificate-revoked: #FF5C00;

@global-color-status-paypal-event-received: #21B57A;
@global-color-status-paypal-event-processed: #8B4FEC;
@global-color-status-paypal-event-failed: #DB4437;

@global-color-status-apple-pay-registered: #8B4FEC;
@global-color-status-apple-pay-not-registered: #F5C505;
@global-color-status-apple-pay-domain-verified: #8B4FEC;
@global-color-status-apple-pay-domain-not-verified: #F5C505;

// TEXT

@global-color-text: #3E3F3E;
@global-color-label-text: #70757A;


// TOOLTIP

@global-color-tooltip-background: @global-color-text;
@global-color-tooltip-box-shadow: #888888;


// DASHBOARD

@global-left-sidebar-open-width: 250px;
@global-left-sidebar-closed-width: 60px;
@global-left-sidebar-horiz-padding-open: 20px;
@global-left-sidebar-horiz-padding-closed: 4px;
@global-left-sidebar-submenu-width-min: 210px;
@global-size-top-bar-height: 70px;
@global-size-page-container-padding: 25px;
@global-size-middle-section-width-min: @global-size-app-min-width - @global-left-sidebar-open-width - (2 * @global-size-page-container-padding);
@global-color-sidebar-separator: #116577;


// BREADCRUMB

@global-font-size-breadcrumb-text: 18px;
@global-line-height-breadcrumb-text: 20px;
@global-font-weight-breadcrumb-text: 500;
@global-color-breadcrumb-regular-text: @global-color-vitu-blue-1;
@global-color-breadcrumb-link-text: @global-color-vitu-blue-2;


// ACTIONS BAR

@global-font-size-actions-bar-title: 18px;
@global-font-weight-actions-bar-title: 500;


// ACTION BUTTON

// ACTION BUTTON : primary

@global-color-action-button-primary-small-default-text: @global-color-vitu-blue-1;
@global-color-action-button-primary-small-default-back: @global-color-vitu-blue-2;

@global-color-action-button-primary-small-hover-text: @global-color-vitu-blue-1;
@global-color-action-button-primary-small-hover-back: #30A4C7;

@global-color-action-button-primary-small-pressed-text: white;
@global-color-action-button-primary-small-pressed-back: #2787A4;

@global-color-action-button-primary-small-disabled-text: @global-color-vitu-blue-1-alpha-50;
@global-color-action-button-primary-small-disabled-back: @global-color-vitu-blue-2-alpha-50;

@global-color-action-button-primary-large-default-text: @global-color-vitu-blue-1;
@global-color-action-button-primary-large-default-back: @global-color-vitu-blue-2;

@global-color-action-button-primary-large-hover-text: @global-color-vitu-blue-1;
@global-color-action-button-primary-large-hover-back: #30A4C7;

@global-color-action-button-primary-large-pressed-text: white;
@global-color-action-button-primary-large-pressed-back: #2787A4;

@global-color-action-button-primary-large-disabled-text: @global-color-vitu-blue-1-alpha-50;
@global-color-action-button-primary-large-disabled-back: @global-color-vitu-blue-2-alpha-50;

// ACTION BUTTON : secondary

@global-color-action-button-secondary-small-default-text: @global-color-vitu-blue-1;
@global-color-action-button-secondary-small-default-back: #EEFBFF;

@global-color-action-button-secondary-small-hover-text: @global-color-vitu-blue-1;
@global-color-action-button-secondary-small-hover-back: #ADEAFC;

@global-color-action-button-secondary-small-pressed-text: @global-color-vitu-blue-1;
@global-color-action-button-secondary-small-pressed-back: #85DEFA;

@global-color-action-button-secondary-small-disabled-text: @global-color-vitu-blue-1-alpha-50;
@global-color-action-button-secondary-small-disabled-back: #EEFBFF80;

@global-color-action-button-secondary-large-default-text: @global-color-vitu-blue-1;
@global-color-action-button-secondary-large-default-back: #EEFBFF;

@global-color-action-button-secondary-large-hover-text: @global-color-vitu-blue-1;
@global-color-action-button-secondary-large-hover-back: #ADEAFC;

@global-color-action-button-secondary-large-pressed-text: @global-color-vitu-blue-1;
@global-color-action-button-secondary-large-pressed-back: #85DEFA;

@global-color-action-button-secondary-large-disabled-text: @global-color-vitu-blue-1-alpha-50;
@global-color-action-button-secondary-large-disabled-back: #EEFBFF80;

// ACTION BUTTON : stroked

@global-color-action-button-stroked-small-default-text: #737373;
@global-color-action-button-stroked-small-default-back: white;

@global-color-action-button-stroked-small-hover-text: #737373;
@global-color-action-button-stroked-small-hover-back: #F1F3F4;

@global-color-action-button-stroked-small-pressed-text: #737373;
@global-color-action-button-stroked-small-pressed-back: #DDE2E5;

@global-color-action-button-stroked-small-disabled-text: #DDDDDD80;
@global-color-action-button-stroked-small-disabled-back: #FFFFFF80;

@global-color-action-button-stroked-large-default-text: #737373;
@global-color-action-button-stroked-large-default-back: white;

@global-color-action-button-stroked-large-hover-text: #737373;
@global-color-action-button-stroked-large-hover-back: #F1F3F4;

@global-color-action-button-stroked-large-pressed-text: #737373;
@global-color-action-button-stroked-large-pressed-back: #DDE2E5;

@global-color-action-button-stroked-large-disabled-text: #DDDDDD80;
@global-color-action-button-stroked-large-disabled-back: #FFFFFF80;

// ACTION BUTTON : alert

@global-color-action-button-alert-small-default-text: #FF3940;
@global-color-action-button-alert-small-default-back: white;

@global-color-action-button-alert-small-hover-text: #FF3940;
@global-color-action-button-alert-small-hover-back: #FFDEDF;

@global-color-action-button-alert-small-pressed-text: white;
@global-color-action-button-alert-small-pressed-back: #FF3940;

@global-color-action-button-alert-small-disabled-text: #FF394080;
@global-color-action-button-alert-small-disabled-back: #FFFFFF80;

@global-color-action-button-alert-large-default-text: #FF3940;
@global-color-action-button-alert-large-default-back: white;

@global-color-action-button-alert-large-hover-text: #FF3940;
@global-color-action-button-alert-large-hover-back: #FFDEDF;

@global-color-action-button-alert-large-pressed-text: white;
@global-color-action-button-alert-large-pressed-back: #FF3940;

@global-color-action-button-alert-large-disabled-text: #FF394080;
@global-color-action-button-alert-large-disabled-back: #FFFFFF80;

// ACTION BUTTON : alert (tone overrides)
@global-color-action-button-alert-neutral-large-default-text: @global-color-vitu-blue-2;
@global-color-action-button-alert-neutral-large-hover-text: #009EE1;
@global-color-action-button-alert-neutral-large-hover-back: #ADEAFC;
@global-color-action-button-alert-neutral-large-pressed-back: @global-color-vitu-blue-2;
@global-color-action-button-alert-neutral-large-disabled-text: #ADEAFC;

@global-color-action-button-alert-positive-large-default-text: #01B85F;
@global-color-action-button-alert-positive-large-hover-text: #01B85F;
@global-color-action-button-alert-positive-large-hover-back: #DCF8EA;
@global-color-action-button-alert-positive-large-pressed-back: #01B85F;
@global-color-action-button-alert-positive-large-disabled-text: #DCF8EA;

@global-size-adjacent-action-buttons-horiz-gap: 15px;

// TABLE

@global-color-table-row-border: #E0E0E0;
@global-color-table-image: #70757A;
@global-color-table-header-row-text: #70757A;
@global-color-table-row-hover: @global-color-panel-background;
@global-color-table-row-select: @global-color-panel-background;

// MENU

@global-color-menu-item-border: #F2F2F2;

// TOAST

@global-color-toast-tone-neutral: @global-color-vitu-blue-2;
@global-color-toast-tone-positive: #01B85F;
@global-color-toast-tone-negative: #FF3940;

// SELECT

@global-color-select-active-and-selected: #30A4C7;
@global-color-select-active-and-not-selected: rgba(0, 0, 0, 0.04);

@global-form-line-regular-layout-max-cols: 6;
@global-form-line-regular-layout-col-span: 2;
@global-form-line-regular-layout-col-gap: 30px;

@global-form-line-short-layout-max-cols: 4;
@global-form-line-short-layout-col-span: 2;
@global-form-line-short-layout-col-gap: 30px;
@global-form-line-short-layout-max-col-width: 250px;

// GLOBAL MIXINS

.global-form-line-regular-layout, .global-form-line-short-layout {
  display: grid;

  > * {
    .global-form-line-combo-field {
      width: 100%;
      > * {
        width: 50%;
      }
    }
  }
}

.global-form-line-regular-layout {
  grid-template-columns: repeat(@global-form-line-regular-layout-col-span * @global-form-line-regular-layout-max-cols, 1fr);
  column-gap: @global-form-line-regular-layout-col-gap;

  > * {
    grid-column-end: span @global-form-line-regular-layout-col-span;
  }
}

.global-form-line-short-layout {
  grid-template-columns: repeat(@global-form-line-short-layout-col-span * @global-form-line-short-layout-max-cols, 1fr);
  column-gap: @global-form-line-short-layout-col-gap;
  max-width: (@global-form-line-short-layout-max-col-width * @global-form-line-short-layout-max-cols) + ((@global-form-line-short-layout-col-gap) * (@global-form-line-short-layout-max-cols - 1));
  margin-right: auto;

  > * {
    grid-column-end: span @global-form-line-short-layout-col-span;
  }
}

.disable-text-select {
  -webkit-user-select: none !important;  // Safari
  -ms-user-select: none !important;      // IE 10 and IE 11
  user-select: none !important;          // Standard, Firefox
}
