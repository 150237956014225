/* You can add global styles to this file, and also import other style files */

@import "globals.less";

@select-panel-width-correction: 25px;

html, body {
  height: 100%;
}

body {
  margin: 0;
  min-width: @global-size-app-min-width;
  min-height: @global-size-app-min-height;
  background-color: white;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none !important;
  font-family: inherit;
}

app-root {
  display: block;
  height: 100%;
  width: 100%;
}

app-dashboard {
  display: block;
  height: 100%;
  width: 100%;
}

/* Scrollbar style : Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: @global-color-scrollbar transparent;
}

/* Scrollbar style : Chrome/Edge/Safari */
*::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}
*::-webkit-scrollbar-track {
  background: transparent;
}
*::-webkit-scrollbar-thumb {
  background-color: @global-color-scrollbar;
  border-radius: 20px;
  border: 3px solid white;
}

.datepicker-suffix-image {
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAIAAABvFaqvAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAABtSURBVDhP7ZQxDoAwCEW9tCMrp+QuSISYBmlCIwxG31T6+W9k4yI6RfuJDTdmaYOIiBBR9zLIslSsPIqWLIpUrDyKLFzEym8Q2ZDA7TeL9H2hnw4X/aIAF8WiDG7/EyIA0CxPfEbKDttDikTMB3Qpd5M3Jl9kAAAAAElFTkSuQmCC');
  width: 24px;
  height: 24px;
}

.modal-datepicker .modal-lg {
    max-width: 850px;
    width: 850px !important;
  }

  .date-picker-close-button {
    color: @global-color-text;
    font-weight: 400;
    transform: translate(12px, -14px) scale(1.3);

    &:hover {
      color: @global-color-text;
    }
  }

  .datepicker {

    button:focus {
      box-shadow: none !important;
    }

    .datepicker-actions {
      padding-top: 50px !important;
      border-top: 1px solid #F1F1F2 !important;
    }

    .datepicker-main-body {
      width: 100%;
      display: flex;
      justify-content: space-between;

      .datepicker-one-month-container {
        flex-grow: 1;
        display: flex;
        justify-content: space-around;
      }

      .datepicker-two-month-container {
        flex: 0 0 585px;
        border-right: 1px solid #F1F1F2;
      }

      .datepicker-side-actions {
        flex: 0 0 130px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .side-action-button {
          border-radius: 3px !important;
        }

        &.hide-datepicker-side-actions {
          display: none;
        }
      }

    }

  }

  ngb-datepicker {

    &:not(.dropdown-menu) {
      border: none !important;
    }

    &.dropdown-menu {
      padding: 10px !important;

      .ngb-dp-arrow {
        position: relative;
        top: -5px;
      }
    }

    .form-select {
      margin: 10px;
      border: none;
      color: #02B3F2;
      font-weight: 600;
      font-size: 15px;
      line-height: 18px;
//      background-image: url(/assets/img/select-arrows.svg);

      cursor: pointer;
      width: auto !important;
      display: inline-block;
      padding-right: 15px;
      outline: none !important;
      box-shadow: none !important;

      option {
        color: #000;
      }

      &:hover {
        opacity: 0.7;
      }
    }

    .btn-link {
      color: #000;
      opacity: 0.6;

      &_fa {
        &.text-info {
          &:hover {
            color: #1D9FD7 !important;
          }
        }
      }
    }

    .ngb-dp-navigation-chevron {
      border-width: .1em .1em 0 0;
    }

    .ngb-dp-month-name,

    .ngb-dp-header,
    .ngb-dp-week {
      background: none !important;
    }

    .ngb-dp-weekday {
      font-style: normal;
      font-weight: bold;
      font-size: 12px;
      line-height: 15px;
      text-align: center;
      color: rgba(0, 0, 0, 0.4);
      text-transform: uppercase;
      width: 40px;
    }

    .ngb-dp-weekday.small {
      font-style: normal;
      font-weight: bold;
      font-size: 12px;
      line-height: 15px;
      text-align: center;
      color: rgba(0, 0, 0, 0.4);
      text-transform: uppercase;
    }

    .ngb-dp-weekdays {
      margin-top: 15px;
      border-bottom: none !important;
    }

    .ngb-dp-day {
      width: 40px !important;
      height: 38px !important;

      &.ngb-dp-today {
        .btn-light {
          background: #ececec !important;
        }
      }

      .btn-light {
        width: 40px !important;
        height: 38px !important;
        line-height: 38px;
        text-align: center;

        &:hover {
          background: #02B3F2 !important;
          color: #fff !important;
        }

        &.bg-primary {
          background: #02B3F2 !important;
          color: #fff !important;
        }
      }
    }

    .custom-day {
      width: 40px !important;
      height: 38px !important;
      line-height: 38px !important;
      font-weight: 500;
      font-size: 14px;
      text-align: center;
      padding: 0 !important;

      &:hover {
        background: #02B3F2 !important;
      }

      &.range {
        background: #02B3F2 !important;

        &.faded {
          background: #E6F8FE !important;
          color: #000;
        }
      }

      &.today {
        background: #ececec;
      }
    }

    button.btn.ngb-dp-arrow-btn {
      border: none !important;
    }
  }

  .modal {
    .modal-dialog {
      &.modal-lg {
        width: 640px;
      }
    }

    &.modal_940 {
      .modal-dialog.modal-xl {
        width: 940px;
      }
    }

    .close {
      margin-right: 11px;
      font-weight: 400;
      font-size: 40px;
      opacity: .2;
      text-shadow: none !important;
    }

    .basic-modal-body {
      padding: 40px;

      .basic-modal-header {
        padding-bottom: 15px;
        border-bottom: 1px solid #F1F1F2;
        margin-bottom: 30px;

        h3 {
          font-weight: bold;
          font-size: 22px;
          line-height: 27px;
          text-transform: none;
        }

        p {
          font-weight: 500;
          font-size: 15px;
          line-height: 18px;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      .form-group {
        margin-bottom: 20px;

        label {
          opacity: 1;
          text-transform: none;
          font-weight: 500;
          font-size: 14px;
          line-height: 17px;
        }
      }

      .basic-modal-actions {
        padding-top: 30px;
        margin-top: 30px;
        border-top: 1px solid #F1F1F2;

        .btn {
          margin-right: 15px;
        }
      }

    }


  }

  .modal-backdrop.show {
    opacity: 0.8;
  }

/* Material Fix : Give mat-tab body 100% height */
.mat-tab-body-wrapper {
    flex-grow: 1;
    margin-top: 10px;
}
.mat-tab-body {
    display: flex !important;
    flex-direction: column;
}
.mat-tab-body-content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.mat-header-row {
  min-height: 36px !important;
  max-height: 36px !important;
  border-bottom-color: @global-color-table-row-border !important;
}

.mat-row {
  height: 52px !important;
  border-bottom-color: @global-color-table-row-border !important;
}

.mat-header-cell, .mat-sort-header-arrow {
  color:@global-color-table-header-row-text !important;
  font-size: 12px !important;
  font-weight: 400 !important;
  .disable-text-select();
}

.mat-cell {
  color: @global-color-text !important;
  font-size: 14px !important;
  font-weight: 400 !important;
}

.global-table-cell-image-only {
  color: @global-color-table-image;
  vertical-align: text-top;
  font-size: 18px !important;
  .disable-text-select();

  &.large {
    font-size: 36px !important;
  }
}

.global-table-cell-image-link {
  color: @global-color-vitu-blue-2;
  vertical-align: text-top;
  font-size: 21px !important;
  .disable-text-select();

  &:hover {
    color: @global-color-vitu-blue-1;
  }
}

.mat-dialog-container {

  .mat-dialog-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 84px;
    font-size: 30px;
    font-weight: 500;
    color: white;
    background-color: @global-color-vitu-blue-1;
    margin: -24px -24px 24px -24px;
    padding-left: 36px;
    padding-right: 32px;

    .global-dialog-close-button {
      box-sizing: border-box;
      padding: 4px;
      font-size: 18px;

      &:hover {
        cursor: pointer;
        color: #CCC;
      }
    }
  }

  .mat-dialog-content {
    color: @global-color-text;
    font-size: 18px;
    font-weight: 400;
    padding: 0 24px 24px 24px;
  }

  .mat-dialog-actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    margin: 0;
    padding: 0;
    min-height: unset;

    > :not(:last-child) {
      margin-right: 15px;
    }
  }

}

/* MATERIAL FORM */

.mat-form-field-appearance-legacy .mat-form-field-infix {
  border-top: 0 !important;
}

// Override Angular Material Form Field (Outline type) to reduce the height (from about 63px to 40px)
.mat-form-field-appearance-outline {

  &.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
    transform: translateY(-17px) scale(.75) !important;
  }
  .mat-form-field-flex > .mat-form-field-infix { padding-top: 6px !important; padding-bottom: 6px !important; width: calc(100% - 54px) !important; }
  .mat-form-field-infix .mat-form-field-label-wrapper { top: -19px !important; }

  .mat-form-field-infix > :not(.mat-form-field-label-wrapper) { position: relative !important; top: -3px !important; }

  .mat-form-field-infix .mat-select-arrow-wrapper { position: relative !important; top: 3px !important; }

  .mat-form-field-flex > .mat-form-field-suffix { max-height: 26px !important; max-width: 26px !important; margin-right: -0.2em !important; }
  .mat-form-field-flex > .mat-form-field-suffix button { max-height: 34px !important; max-width: 34px; top: 0 !important; left: 0 !important; }

  .mat-form-field-flex .mat-button-focus-overlay { background-color: transparent !important; overflow: hidden !important;}
  .mat-form-field-infix { font-size: 14px !important; line-height: 18px !important; }
  .mat-form-field-prefix { top: -2px !important;}
  .mat-form-field-wrapper > .mat-form-field-subscript-wrapper { margin-top: 4px !important; }
  .mat-error { font-size: 12px !important; }
  .mat-form-field-infix .mat-select.mat-select-disabled .mat-select-arrow { color: transparent !important; }

  @form-field-border-radius: 4px; // 5px is default value

  .mat-form-field-outline {
    background-color: white !important;
    border-radius: @form-field-border-radius !important;
  }
  .mat-form-field-outline-end {
    border-radius: 0 @form-field-border-radius @form-field-border-radius 0 !important;
  }
  .mat-form-field-outline-start {
    border-radius: @form-field-border-radius 0 0 @form-field-border-radius !important;
  }

  // focused
  &:not(.mat-form-field-disabled).mat-focused .mat-form-field-outline {
    color: @global-color-vitu-blue-1 !important;

    .mat-form-field-outline-start, .mat-form-field-outline-gap, .mat-form-field-outline-end {
      border-width: 2px !important;
    }
  }

  // hover
  &:not(.mat-form-field-disabled):hover .mat-form-field-outline {
    color: @global-color-vitu-blue-1 !important;

    .mat-form-field-outline-start, .mat-form-field-outline-gap, .mat-form-field-outline-end {
      border-width: 2px !important;
    }
  }

  // non-empty value
  &:not(.mat-form-field-disabled).global-form-field-highlight-non-empty.ng-valid:not(.mat-form-field-hide-placeholder):not(:hover):not(.mat-focused) .mat-form-field-outline {
    color: @global-color-vitu-blue-2 !important;

    .mat-form-field-outline-start, .mat-form-field-outline-gap, .mat-form-field-outline-end {
      border-width: 2px !important;
    }
  }

  // disabled
  &.mat-form-field-disabled .mat-form-field-outline {
    background-color: #F4F4F4 !important;
  }

}

// TODO : Redesign form field validation so that this is not required?
//        Currently needed because an form element must be in the DOM to
//        disable it (by 'disabled' attribute).  If its not in the DOM
//        then the validation can fails if the field is invalid and
//        part of the validation set (eg. formConfig).
.vitu-hide-form-field {
  display: none !important;
}

// label
.mat-form-field-label {
  color: @global-color-label-text !important;
  font-weight: 400 !important;

  .mat-placeholder-required.mat-form-field-required-marker {
    color: @global-color-label-text !important;
  }

  mat-label {
    // Magically allows vitu-tooltip to appear on 
    // hovering over a form field label 'i' info tip.
    pointer-events: auto !important;
  }
}

// input
.mat-input-element {
  color: @global-color-text !important;
}

// select

.select-panel-base-multi-select,
.select-panel-base-single-select {
  position: absolute;
  top: 29px !important;
  min-width: calc(100% + @select-panel-width-correction) !important;
  max-width: unset !important;

  &.limited-vertical-space {
    max-height: 150px !important;
  }

  .mat-option {

    &:not(.mat-selected) .mat-option-text {
      color: @global-color-text !important;
    }

    &.mat-active {
      background: @global-color-select-active-and-not-selected !important;
    }

    &.mat-selected {
      color: @global-color-vitu-blue-1 !important;
      font-weight: 600 !important;

      background: @global-color-vitu-blue-2 !important;
      &.mat-active {
        background: @global-color-select-active-and-selected !important;
      }

      .mat-pseudo-checkbox.mat-pseudo-checkbox-checked {
        background: white !important;
      }

      .mat-pseudo-checkbox.mat-pseudo-checkbox-checked::after {
        color: @global-color-vitu-blue-1 !important;
      }
    }

  }


}

.select-panel-base-multi-select {
  left: 29px !important;
}

.select-panel-base-single-select {
  left: 5px !important;
}

.mat-select-value {
  color: @global-color-text !important;
}

.mat-select-disabled .mat-select-trigger {
  -webkit-user-select: initial !important;
  user-select: initial !important;
  cursor: default !important;
}

/* MATERIAL TAB GROUP */

// tab underline
.mat-tab-group {

  &.mat-primary .mat-ink-bar, .mat-tab-nav-bar.mat-primary .mat-ink-bar{
    background: @global-color-vitu-blue-2 !important;
  }

  .mat-tab-header {
    .disable-text-select();
    border-bottom: none !important;
  }

  .mat-tab-label {
    color: @global-color-vitu-blue-2 !important;
    font-size: 14px !important;
    font-weight: 500 !important;
  }

  .mat-tab-label.mat-tab-label-active {
    font-size: 16px !important;
    background-color: @global-color-panel-background !important;

    &:not(.mat-tab-disabled), cdk-keyboard-focused:not(.mat-tab-disabled) {
      color: @global-color-vitu-blue-1 !important;
      font-size: 16px !important;
      font-weight: 500 !important;
      opacity: 1 !important;
    }

  }

}

/* MATERIAL SLIDE TOGGLE */

.mat-slide-toggle .mat-slide-toggle-bar {
  background-color:#9EB5BC !important;
}

.mat-slide-toggle .mat-slide-toggle-thumb {
  background-color: white !important;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: #BCEAF8 !important;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: @global-color-vitu-blue-2 !important;
}

.mat-slide-toggle .mat-ripple-element {
  background-color: @global-color-vitu-blue-2 !important;
}

/* MATERIAL BUTTON */

.mat-button-focus-overlay {
  background-color: transparent !important;
}

/* MATERIAL MENU */

.mat-menu-panel {
  min-height: unset !important;
}

.mat-menu-content:not(:empty) {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

/* MATERIAL SNACKBAR */

/*
.mat-snack-bar-container {
  background: @global-color-vitu-blue-2 !important;
  color: white !important;
}
*/
.vitu-material-snackbar-override {
  background-color: white;
  box-shadow: 4px 4px 10px #888 !important;
  padding: 0 !important;
  max-width: none !important;
}

