/* Override Angular Material Theme in here */

@import "~@angular/material/theming";

@include mat-core();

// override $mat-blue-grey
$vpay-palette-primary: (
  50: #eceff1,
  100: #cfd8dc,
  200: #b0bec5,
  300: #90a4ae,
  400: #78909c,
  500: #013D4F, // override #607d8b to apply @global-color-vitu-blue-1
  600: #546e7a,
  700: #455a64,
  800: #37474f,
  900: #263238,
  A100: #cfd8dc,
  A200: #b0bec5,
  A400: #78909c,
  A700: #455a64,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $light-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $dark-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text,
  )
);

// override $mat-pink
$vpay-palette-accent: (
  50: #fce4ec,
  100: #f8bbd0,
  200: #f48fb1,
  300: #f06292,
  400: #ec407a,
  500: #4FC8EC, // // override #e91e63 to apply @global-color-vitu-blue-2
  600: #d81b60,
  700: #c2185b,
  800: #ad1457,
  900: #880e4f,
  A100: #ff80ab,
  A200: #ff4081,
  A400: #f50057,
  A700: #c51162,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $light-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text,
  )
);

// override $mat-deep-orange
$vpay-palette-warn: (
  50: #fbe9e7,
  100: #ffccbc,
  200: #ffab91,
  300: #ff8a65,
  400: #ff7043,
  500: #FF3940, // override #ff5722 to apply @global-color-warning
  600: #f4511e,
  700: #e64a19,
  800: #d84315,
  900: #bf360c,
  A100: #ff9e80,
  A200: #ff6e40,
  A400: #ff3d00,
  A700: #dd2c00,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $dark-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text,
  )
);

// $my-app-primary: mat-palette($mat-blue-grey);
$my-app-primary: mat-palette($vpay-palette-primary);
$my-app-accent: mat-palette($vpay-palette-accent, 500, 900, A100);
$my-app-warn: mat-palette($vpay-palette-warn);
$my-app-theme: mat-light-theme($my-app-primary, $my-app-accent, $my-app-warn);

@include angular-material-theme($my-app-theme);
